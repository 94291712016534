<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-program
          v-model:value="state.params.program"
          class="mr-2 mt-2" />
        <FilterToko
          v-model:value="state.params.toko"
          class="mr-2 mt-2" />
        <ASelect
          class="mr-2 mt-2"
          v-model:value="state.params.status_mapping"
          show-search
          allow-clear
          placeholder="Pilih Status Mapping"
          style="width: 300px"
          :options="state.statusMappingLists"
        />
        <ASelect
          class="mr-2 mt-2"
          v-model:value="state.params.status"
          show-search
          allow-clear
          placeholder="Pilih Status Registrasi"
          style="width: 300px"
          :options="state.statusLists"
        />
        <a-input-search
          v-model:value="state.params.q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mt-2"
        />
        <a-button class="m-2 mt-2" type="primary" title="cari" @click="fetchData()" :loading="state.isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!state.isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <ASpace>
          <ATooltip title="Download Excel Mapping">
              <DownloadExcel
                :url="state.endpoint.mappping"
                :params="state.params"
                namefile="List-Toko-Mapping"
                @errors="errorMessage"/>
          </ATooltip>
          <ATooltip  title="Download Excel">
              <a-button
                title="download excel"
                type="primary"
                @click="fetchXlsx"
                :loading="state.isDownloading"
              >
                <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.isDownloading"></i>
                <span v-else>Downloading ...</span>
              </a-button>
          </ATooltip>

          
        </ASpace>
      </div>
    </div>
    <div class="table-responsive text-nowrap mt-2">
      <md-table
        row-key="id"
        :columns="columns"
        :data-source="state.data"
        size="small"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: state.meta.total,
          pageSize: state.meta.per_page,
          current: state.meta.page,
        }"
        @change="handleTableChange"
        :loading="state.isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ state.meta.from + index }}
          </span>
        </template>
        <template #formatDate="{text}">
          <span>{{ text ? moment(text).format('DD-MM-yyyy') : '' }}</span>
        </template>
        <template #status="{ text }">
          <a-tag v-if="text" color="#5cb85c">Aktif</a-tag>
          <a-tag v-else color="#fd6464">Tidak Aktif</a-tag>
        </template>

        <template #action="{ record }">
          <span>
            <ATooltip
              title="Detail History Mapping"
              placement="left">
              <button
                type="button"
                @click="btnDetail(record.history_mapping)"
                class="btn btn-sm btn-success"
                shape="round"
                size="small">
                <i class="fe fe-info fa-lg" />&nbsp;Detail
              </button>
            </ATooltip>
          </span>
        </template>
      </md-table>
    </div>

    <!-- detail history mapping -->
    <DetailHistoryMapping
      v-if="visibleDetailModal"
      v-model:visible="visibleDetailModal"
      v-model:items="visibleDetailItemsModal"/>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted, computed, watch } from 'vue'
import acolumns from './columns'
import qs from 'qs'
import moment from 'moment'
import apiClient from '@/services/axios'
import { useRoute } from 'vue-router'
import FilterProgram from '@/components/filter/FilterProgram'
import FilterToko from '@/components/filter/FilterToko'
import DetailHistoryMapping from './DetailHistoryMapping'
import DownloadExcel from '@/components/Molecules/DownloadExcel'

export default defineComponent({
  components: {
    FilterProgram,
    FilterToko,
    DetailHistoryMapping,
    DownloadExcel,
  },
  setup() {
    const state = reactive({
      data: [],
      endpoint: {
        mappping: '/api/loyalty-program-store-mapping',
      },
      meta: {
        from: 1,
        total: 0,
        per_page: 10,
        page: 1,
      },
      params: {
        q: '',
        status: null,
        status_mapping: null,
        program: [],
        toko: [],
        page: 1,
        'per-page': 10,
      },
      isFetching: false,
      isDownloading: false,
      statusLists: [
        {
          label: 'Belum Terdaftar',
          value: 1,
        },
        {
          label: 'Belum Verifikasi',
          value: 2,
        },
        {
          label: 'Terdaftar',
          value: 3,
        },
      ],
      statusMappingLists: [
        {
          label: 'Aktif',
          value: 1,
        },
        {
          label: 'Tidak Aktif',
          value: 0,
        },
      ],
    })

    const route = useRoute()
    const visible = ref(false)

    const handleTableChange = (pag, filters, sorter) => {
      state.params.page = pag.current
      state.params['per-page'] = pag.pageSize
      fetchData()
    }

    const errorMessage = ref()

    const columns = ref([])
    columns.value = acolumns

    const fetchData = () => {
      state.isFetching = true
      apiClient
        .get('/api/loyalty-program-stores', {
          params: state.params,
        })
        .then(({ data: { items, _meta } }) => {
          state.data = items
          state.meta.page = _meta.currentPage
          state.meta.per_page = _meta.perPage
          state.meta.total = _meta.totalCount
          state.meta.from = (_meta.currentPage - 1) * _meta.perPage + 1
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/loyalty-program-stores', {
          params: {
            ...state.params,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `list_toko_program_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    // handle detail by item
    const visibleDetailModal = ref(false);
    const visibleDetailItemsModal = ref(null);

    const btnDetail = (items) => {
        visibleDetailModal.value = true
        visibleDetailItemsModal.value = items
    }

    onMounted(() => {
      if (route.params.id) {
        state.params.program.push(parseInt(route.params.id))
      }
      fetchData()
    })

    return {
      fetchData,
      visible,
      columns,
      handleTableChange,
      state,
      fetchXlsx,
      errorMessage,
      moment,
      visibleDetailModal,
      visibleDetailItemsModal,
      btnDetail,
    }
  },
})
</script>
<style scoped>
.card-body {
  margin-left: 20px;
}
.button {
  margin: 0 3px;
}
</style>
